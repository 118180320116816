(function () {
  'use strict';

  angular
  .module('neo.home.referees.referee')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.referees.referee', {
      url: '/{licenseId}',
      views: {
        '': {
          templateUrl: 'home/referees/referee/referee.tpl.html',
          controller: 'RefereeCtrl',
          controllerAs: 'vm'
        }
      },
      resolve:{
        genders: function (Genders) {
          return Genders.query().$promise;
        },
        entities: function (Entitats) {
          return Entitats.query().$promise;
        },
        townHalls: function (Ajuntaments) {
          return Ajuntaments.query().$promise;
        },
        refereeLicense: function (RefereeLicence,  $stateParams){
          return RefereeLicence.get({licenceId :  $stateParams.licenseId}).$promise;
    }
      }
    });
  }
}());
